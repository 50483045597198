<template>
    <b-container fluid>
        <h2 class="text-center my-4">Novi nalog za knjiženje</h2>
        <b-row>
            <b-col class="flex-center">
                <b-form v-on:submit.prevent="createEntryOrder" class="w-75">
                    <b-form-group :label-cols-md="4"
                                  label="Opis dokumenta:"
                                  label-for="description">
                        <b-form-input id="description"
                                      type="text"
                                      size="sm"
                                      name="description"
                                      required></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Broj dokumenta:"
                                  label-for="document_number">
                        <b-form-input id="document_number"
                                      type="text"
                                      size="sm"
                                      name="document_number"
                                      required></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Datum dokumenta:"
                                  label-for="document_date">
                        <date-picker format="DD.MM.YYYY."
                                     v-model="document_date"
                                     :lang="$parent.hrv"
                                     :first-day-of-week="1"
                                     class="w-100"
                                     :input-attr="{required: true}"
                                     id="document_date">
                        </date-picker>
                    </b-form-group>
                    <b-form-group :label-cols-md="4">
                        <b-form-radio-group
                                id="type"
                                name="type"
                                v-model="type">
                            <b-form-radio value="0">Kupci/Dobavljači</b-form-radio>
                            <b-form-radio value="1">Banka - Blagajna</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-group :label-cols-md="4">
                        <b-form-radio-group
                                id="variant"
                                name="variant"
                                v-model="variant">
                            <b-form-radio value="0">Knjiga A</b-form-radio>
                            <b-form-radio value="1">Knjiga B</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-group :label-cols-md="4">
                        <b-form-radio-group
                                id="book_type"
                                v-model="has_book_type">
                            <b-form-radio value="0">Nema</b-form-radio>
                            <b-form-radio value="1">Tip dokumenta KIF</b-form-radio>
                            <b-form-radio value="2">Tip dokumenta KUF</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-group :label-cols-md="4" v-if="has_book_type !== '0'">
                        <b-form-select id="document_category" name="document_category_id" v-model="document_category_id"
                                       size="sm" class="mt-3">
                            <option :value=null disabled>Izaberite tip:</option>
                            <option v-if="has_book_type === '1'" v-for="documentCategory in document_categories_kif"
                                    :key="documentCategory.id"
                                    :value="documentCategory.id">{{documentCategory.code}} - {{ documentCategory.title }}
                            </option>
                            <option v-if="has_book_type === '2'" v-for="documentCategory in document_categories_kuf"
                                    :key="documentCategory.id"
                                    :value="documentCategory.id">{{documentCategory.code}} - {{ documentCategory.title }}
                            </option>
                        </b-form-select>

                    </b-form-group>
                    <b-form-group :label-cols-md="4">
                        <b-form-checkbox
                                name="in_kpr_form"
                                v-model="kprFormStatus"
                                value="1"
                                unchecked-value="0">
                            Ulazi u knjigu prihoda i rashoda
                        </b-form-checkbox>
                    </b-form-group>
                    <b-btn type="submit" variant="primary" class="pull-right">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        data() {
            return {
                kprForms: null,
                kprFormStatus: 0,
                variant: 0,
                type: 0,
                document_date: null,
                has_book_type: '0',
                document_category_id: null,
                document_categories_kif: null,
                document_categories_kuf: null
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        watch: {
            has_book_type: function (newValue, oldValue) {
                    this.document_category_id = null
            }
        },
        methods: {
            createEntryOrder: function (e) {
                const formData = new FormData(e.target);
                formData.append('client_id', this.selectedClient.id);
                formData.append('document_date', this.$moment(this.document_date).format("YYYY-MM-DD"));
                    axiOsta.post(api.entryOrders, formData).then(response => {
                        if (response.status === 201) {
                            this.$router.push('/entry_orders/' + response.data.id);
                        }
                    });
            }
        },
        created() {
            axiOsta.get(api.documentCategories)
                .then(response => {
                    if (response.status === 200) {
                        this.document_categories_kif = response.data.filter(item => item.book_type === 1)
                        this.document_categories_kuf = response.data.filter(item => item.book_type === 2)
                    }
                });
        }
    }
</script>
